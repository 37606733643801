@import url('https://fonts.googleapis.com/css?family=Poppins');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'aliens and cows';
  src: local('aliens and cows'),
    url(./assets/fonts/aliens\ and\ cows_trial.ttf) format('truetype');
}

.button-payment {
  width: 95%;
  margin: 1% 0%;
}
.navbar-toggler {
  width: 15px;
  height: 15px;
  position: fixed;
  transition: 0.5s ease-in-out;
}

th > div {
  font: normal normal 400 9px Poppins !important;
  color: #b8998d !important;
}
td {
  font: normal normal 400 12px Poppins !important;
}
td > a {
  color: #b8998d !important;
}
.search {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dddddd !important;
  border-radius: 25px !important;
  width: 400px !important;
}
.displayVisible {
  display: none !important;
}
.SidebarNav {
  width: 100% !important;
}
.margin-right-2 {
  margin-right: 7px !important;
}

.margin-right-2:focus {
  box-shadow: none !important;
}
.marginDateLeft {
  margin-left: -1rem;
}
.marginAmountLeft {
  margin-left: -2rem;
}
.statusMrgn {
  margin-left: 4.5rem;
}

.search-input {
  font: normal normal normal 12px/15px Montserrat;
  box-shadow: none !important;
}
.btn-setting {
  background: #b8998d 0% 0% no-repeat padding-box !important;
  border: 1px solid #b8998d !important;
  border-radius: 4px !important;
  font: normal normal 400 12px/18px Poppins !important;
  letter-spacing: 0px;
  padding-inline: 20px !important;
  max-height: 3rem;
  color: #ffffff !important;
  text-transform: uppercase;
}

.forgot-link {
  background: #ffffff !important;
  border: none !important;
  text-decoration: none !important;
  font: normal normal normal 14px/18px Poppins !important;
  color: #cfbdab;
  text-transform: uppercase;
  cursor: pointer;
}

.bg-color {
  background-color: #b8998d !important;
  width: 100vw !important;
}

.bride-img {
  background-repeat: no-repeat !important;
  width: 100% !important;
  transform: scale(1.2, 1.2);
}

.login-bg {
  width: 55% !important;
}

.dropdownbutton {
  width: 200px;
  text-align: start !important;
}

.grid-header {
  font: normal normal 600 9px/16px Poppins !important;
  color: #767676 !important;
  /* text-transform: uppercase; */
}
.grid-headerNew{
  font: normal normal 600 13px/16px Poppins !important;
    color: #767676 !important;
}

.dropdownbutton:focus {
  box-shadow: none !important;
}

.title-text {
  font: normal normal 600 15px poppins;
}

.subtitle-text {
  font: normal normal normal 11px poppins;
}

.login-bg {
  background-position: top !important;
  background-size: cover !important;
  background-size: 100% !important;
  background-repeat: no-repeat;
}
.forgot-link:hover {
  background-color: #ffffff !important;
  color: #cfbdab !important;
  border: none !important;
}

.logout-logo {
  width: 21px;
  height: 21px;
}

.sign-text {
  color: #b8998d !important;
}

.remember-text {
  color: #43425d;
  font: normal normal normal 14px/18px Poppins !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins' !important;
  max-width: 100%;
}

.links a:hover {
  color: #b8998d !important;
}

.btn-hover:hover {
  color: #b8998d !important;
}

.color-secondary button,
.color-secondary button:active {
  font: normal normal 400 12px/18px Poppins;
  letter-spacing: 0px;
  color: #b8998d;
  text-transform: uppercase;
}

.color-secondary button:focus {
  font: normal normal 400 12px/18px Poppins;
  letter-spacing: 0px;
  color: #b8998d !important;
  text-transform: uppercase;
  box-shadow: none !important;
}

input {
  height: 45px;
}

.logoFont {
  font: normal normal normal 25px/21px aliens and cows;
  letter-spacing: 0px;
  color: #43425d;
}

.circle {
  width: 42px;
  height: 43px;
  border-radius: 50%;
  background: #cfbdab 0% 0% no-repeat padding-box;
}

.show-label {
  display: none !important;
}

.desc {
  font: normal normal 300 16px/24px Poppins;
  letter-spacing: 0px;
  color: #4d4f5c;
}

.check-btn {
  background-color: transparent !important;
  border: none !important;
  color: #ffffff !important;
}

.input-lineBorder {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: inherit !important;
}

.grid-cell-body {
  font: normal normal normal 12px/18px Poppins !important;
  color: #43425d !important;
  text-decoration: none !important;
}

.grid-cell-title {
  font: normal normal normal 10px/18px Poppins !important;
  color: #43425d !important;
}

.grid-cell-body :focus {
  border: none;
}

.grid-status {
  padding-left: 2rem !important;
}
.order-imageCustom{
  max-width: 100% !important;
  height: auto !important;
}

.table {
  table-layout: fixed;
  width: 100%;
  max-width: 550px;
}

.table th,
.table td {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


@media (max-width: 375px) {
  .scrolY {
      overflow-y: hidden !important;
    }
  .marginAc {
    margin-left: 1rem;
  }
  /* .Marg {
    margin-left: -1rem;
  } */
  .row-Pay {
    max-width: 95vw !important;
  }
  .mxwidth {
    max-width: 36rem;
  }
  .paddingLeft {
    margin-left: 4.8rem;
  }
  .mrgnColor {
    margin-left: 3.5rem;
  }
  .widthmx {
    max-width: 20px !important;
  }
  .invoice {
    max-width: 53rem !important;
  }

  .marginAmountLeft {
    margin-left: 2rem;
  }
  .widthmaxMed {
    max-width: 99vw !important;
  }
  .scrollX {
    overflow-x: hidden !important;
  }
  .ScrollXHiddenOnMediumMobile{
overflow-x:hidden ;
  }
  .order-image {
    width: 100vw !important;
  }
  .widthLeft {
    margin: auto;
  }
  .widtSta {
    max-width: 100vw !important;
  }
  .addresRowHr {
    margin-left: 2.2rem;
    width: 165% !important;
  }
  .widthInvoiceList {
    width: 90vw !important;
  }

  .invoice-eye {
    margin-top: -5.5rem;
    margin-left: -3rem;
  }

  .imgInvoice {
    margin-top: -16rem !important;
  }

  .hideTitle {
    display: none;
  }

  .searchForm {
    max-width: 100% !important;
  }

  .hrSales {
    width: 92% !important;
  }

  .width-row-sales {
    max-width: 50% !important;
  }

  .margin-top-input {
    margin-top: -10px;
  }

  .hrTable {
    width: 500px !important;
  }

  .width-export {
    width: fit-content !important;
  }
     .width-200Exp {
       width: fit-content !important;
     }
  .widthInv {
    width: 200%;
  }

  .widtSet {
    width: 117% !important;
  }
  .marginLeftE {
    margin-left: 9rem;
}

  .export-title {
    padding-right: 8rem !important;
  }

  .width-card {
    width: 115% !important;
  }

  .width-label {
    display: none !important;
  }

  .width-subtitle {
    width: 110% !important;
  }

  .width-subtitle-payment {
    width: 300% !important;
  }

  .width-row {
    width: 95% !important;
    height: 50px !important;
  }

  .width-custom-row {
    width: 33% !important;
  }

  .navbar-toggler:focus {
    box-shadow: none !important;
  }

  .size-btn {
    width: 21px;
    height: 14px;
  }

  .sidebar-bg {
    /* margin-top: 1rem !important; */
    background-color: #ffffff !important;
  }

  .desc-semiBold,
  .desc {
    font: normal normal 300 13px Poppins !important;
  }

  .grid-height {
    padding-top: 1rem;
    margin-top: -1rem !important;
  }

  .grid-image-checkout {
    margin-top: -6rem;
  }

  .grid-eye-checkout {
    margin-top: -6.5rem !important;
  }

  .show-label {
    display: flex !important;
  }

  .btn-main {
    width: 93% !important;
    font: normal normal 300 11px Poppins !important;
    margin: auto;
  }

  .bride-img {
    height: 90% !important;
    width: 100% !important;
    object-fit: cover !important;
    margin: -40px 0px -240px 0px;
  }

  .login-bg {
    width: 100vw !important;
  }

  .style-screen {
    padding-inline: 20% !important;
    padding-left: 23% !important;
  }

  .img-size {
    width: 105px !important;
    height: 46px !important;
    margin-top: -10%;
    text-align: center !important;
    margin-left: 3rem !important;
  }

  html {
    overflow-x: hidden !important;
  }

  .input-style {
    max-width: 80vw !important;
  }

  .remember-text {
    font: normal normal normal 10px Poppins !important;
  }

  .width-xs {
    width: fit-content !important;
  }

  .width-custom-xs {
    max-width: 20% !important;
  }

  .width-description {
    max-width: 20% !important;
    margin-left: 17px !important;
  }

  .grid-image {
    height: 50px !important;
    width: 35px !important;
  }

  .logoFont-small {
    background-color: transparent !important;
    margin-top: 2rem;
  }

  .search {
    max-width: 120% !important;
  }

  .marginLeft {
    margin-right: -45% !important;
  }

  .width-btn {
    width: fit-content !important;
    margin: auto !important;
  }

  .grid-cell {
    width: 20% !important;
  }

  /* .collapse{
        background-image: url(/src/assets/img/BridalImg.jpg) !important;
        background-size: cover ;
        background-position: 40% !important;
        background-repeat: no-repeat !important;
     } */

  .active {
    background-color: transparent !important;
  }

  .height-30 {
    width: fit-content !important;
  }

  .style-start {
    margin-left: -25px !important;
  }

  .style-name {
    margin-left: 17px !important;
  }

  .row-style {
    padding: 0px !important;
    width: 80% !important;
  }

  .btn-style-row {
    margin-right: -4rem !important;
    width: 120% !important;
  }

  .styleWidth {
    width: 32% !important;
  }

  .display-block {
    display: block !important;
  }

  .styleWidthAddress {
    width: 109% !important;
  }

  .row-styleWidSet {
    width: 25rem !important;
  }

  .styleWidthInput {
    width: 110% !important;
  }

  .row-styleWidth {
    width: 100% !important;
  }

  .toggle-style {
   width: 89% !important;
    padding-top: 67px !important;
  }

  .toggle-input {
    margin-top: 1rem;
  }

  .navbar-toggler {
    margin-top: -4rem !important;
  }

  .paymen-hr {
    width: 150% !important;
    margin-left: -0.8rem;
  }

  .payment-button {
    width: 150% !important;
  }

  .row-button {
    width: 83%;
    margin-right: 12.8rem;
    margin-left: -0.8rem;
  }
  .fontSize {
    font-size: 10px !important;
  }

  .settings-width {
    max-width: 19% !important;
  }

  .input-purchase {
    width: 105% !important;
  }

  .payment-text {
    font: normal normal 600 11px Poppins !important;
  }

  .settings-text {
    font: normal normal 600 8px Poppins !important;
    /* width: 18% !important; */
  }

  .btn-setting {
    padding-inline: 10px !important;
  }
  .row-button-purchase {
    width: 100% !important;
    margin-right: 7.5rem;
  }

  .name-text {
    padding-left: 25px !important;
  }
  .paddLeft {
    padding-left: 1rem !important;
  }

  /* .color-text {
    margin-left: 25px !important;
  } */

  .width-account {
    width: 120% !important;
  }

  .btn-setting {
    width: 171px;
    height: 45px;
  }

  .fit-width {
    width: 220% !important;
  }

  .width-item {
    width: 18% !important;
    font: normal normal normal 10px Poppins !important;
  }

  .width-style {
    margin-left: 1rem;
  }

  .width-sales {
    width: 5.5rem !important;
  }

  .width-amountCha {
    width: 12rem !important;
    margin-left: -2rem !important;
  }
  .dateSty {
    margin-left: 1.5rem;
  }
  .statusPad {
    margin-left: -2.3rem !important;
  }

  .width-amount {
    width: 12rem !important;
    margin-left: -4rem;
  }
  .shipping-track {
    margin-left: -2rem !important;
  }

  .shipping-trackInv {
    width: 30% !important;
  }

  .widthSt {
    margin-left: 6rem;
  }
  .margin-top-sum {
    margin-top: 1rem !important;
  }
  .grid-status {
    padding-left: 0rem !important;
  }

  .width90 {
    width: 90vw !important;
  }

  .width-check {
    width: 2rem !important;
  }

  .grid-header {
    font: normal normal 600 8px/16px Poppins !important;
  }

  .sales-text {
    margin-left: -40px;
  }

  .status-text {
    margin-left: -1.5rem !important;
  }

  .statusText {
    margin-left: 1rem !important;
  }

  .width-fully {
    width: 40rem !important;
  }

  .date-width {
    margin-left: -1.5rem !important;
  }

  .margin-right {
    margin-left: -60px !important;
  }

  .margin-text {
    margin-left: 3rem;
  }

  .link {
    font: normal normal normal 10px Poppins !important;
  }

  .fit-sales {
    width: 20% !important;
  }

  .padding-export {
    margin-left: -16rem !important;
  }

  .padding-export-2 {
    margin-left: -10.5rem !important;
  }

  .padding-export-3 {
    margin-left: -7rem !important;
  }

  .styleWidthView {
    width: 130% !important;
  }

  .styleWidthImage {
    width: 33% !important;
  }

  .btn-style-view {
    margin-left: 1rem;
    margin-right: -0.5rem;
    width: 72rem !important;
  }

  .width-start-row {
    width: 100% !important;
  }

  .width-subtitle-title {
    width: 100% !important;
  }

  .forgot-link {
    padding-right: -3rem !important;
    background: transparent;
    border: transparent;
    text-decoration: none !important;
    color: #cfbdab;
    text-transform: uppercase;
    cursor: pointer;
    font: normal normal normal 10px Poppins !important;
  }

  .export-text {
    margin-left: -15rem !important;
  }

  .width-transaction {
    width: 9% !important;
  }

  .exportTrans {
    margin-left: -300px !important;
  }

  .width-itemSales {
    width: 16% !important;
    font: normal normal normal 10px/18px Poppins !important;
  }

  .fit-salesOrder {
    width: 20% !important;
  }

  .width-row-order {
    width: 30% !important;
  }

  .payment-check {
    max-width: 26% !important;
  }

  .paymentRow {
    max-width: 100% !important;
  }

  .PaymentBtn {
    max-width: 93vw !important;
  }

  .addressWidth {
    max-width: 20% !important;
  }

  .grid-row-border {
    width: 97% !important;
    max-width: 109% !important;
    font-size: 9px !important;
  }
  /* .addresRow {
    max-width: 50% !important;
  } */
  .cardAddresWidth {
    width: 95vw;
  }

  .width-subtitle-payment {
    width: 150% !important;
  }
  .widthresp {
    width: 66vw !important;
  }
  .row-styleWid {
    margin-right: 9rem;
    width: 120% !important;
  }
  .width-200 {
    width: 170% !important;
  }

  .amountCharge {
    width: 15% !important;
  }
  .widthSummary {
    width: 150% !important;
    max-width: 150% !important;
  }

  .width-transactionText {
    width: 20% !important;
  }
  .invoice .width-200 {
    width: 50rem !important;
    max-width: 70rem !important;
  }
  .invoice .width-amount {
    margin-left: 0 !important;
  }
  .invoice .shipping-trackInv {
    width: 8% !important;
  }
  .invoice .date-width {
    margin-left: 0rem !important;
  }
  .invoice .margin-text {
    margin-left: 5rem;
  }
  .invoice .ms-5 {
    margin-left: 0rem !important;
  }
  .invoice .marginInv {
    padding-left: 5rem !important;
  }

  .invoice .status-margin {
    margin-left: 2.5rem !important;
  }
  .invoice .width-amount {
    width: 9rem !important;
  }
  .invoice .margin-right {
    margin-right: 3rem;
    margin-left: -1rem !important;
  }
  .invoice .moveToLeft {
    margin-right: 13px !important;
    margin-left: -17px !important;
  }
  .width-itemInvoice {
    width: 11% !important;
  }
  .status-margin {
    margin-left: 1rem !important;
  }

  .row-transaction {
    max-width: 85vh !important;
  }
  .widthTran {
    width: 160% !important;
  }

  .navbar-nav {
    width: inherit !important;
  }

  .widthOrd {
    width: 70vw !important;
  }

  .widthLinks {
    width: 100vw !important;
  }

  .orderHeader {
    max-width: 100vw;
  }

  .width150 {
    width: 100% !important;
  }
  .settings-width-row {
    width: 85% !important;
  }

  .nameStore {
    margin-top: -5rem !important;
  }
  .widthPaymentOnTablet {
    max-width: 93vw !important;
  }

  .fit-salesOrder {
    width: 40rem !important;
  }

  .fit-sale {
    width: 100% !important;
  }

  .width-row-header {
    width: 100% !important;
  }
  .hrInv {
    width: 94% !important;
  }
  .statusWidthMobileM{
    width: 12% !important;
  }
  .width-Invdate {
    width: 65px !important;
}
.invoice .width-Inv {
  width: 40px !important;
  margin-right: 3rem;
}
.invoice .width-amount {
  width: 12rem !important;
  margin-right: -0.8rem;
}
.width-InvStatus {
  width: 62px !important;
}
.width-InvShip {
  width: 150px !important;
}

.widthForMed{
  width: 91vw !important;
}

.modal-img {
  width: 15rem !important;
}

}

@media (max-width: 320px) {
  .marginLeftE{
    margin: auto !important;
  }
  .flex-title {
    margin-left: 1rem;
  }

  .grid-header {
    width: 150% !important;
    margin-left: 0.5rem !important;
  }

  .row-button {
    width: 65% !important;
  }

  .width-subtitle {
    width: 100% !important;
  }

  .width-subtitle-payment {
    width: 350% !important;
  }

  .payment-button {
    width: 170% !important;
  }

  .width-btn {
    width: fit-content !important;
    margin: auto !important;
  }

  .grid-cell {
    width: 10% !important;
  }

  .width-style {
    width: 8.5% !important;
  }

  .search {
    max-width: 60% !important;
    margin-left: 1rem;
  }

  .style-title {
    margin-left: 5px !important;
  }

  .style-name {
    margin-left: 30px !important;
  }

  .title-width {
    font: normal normal 500 9px Poppins !important;
  }

  .row-button-purchase {
    width: 57% !important;
  }

  .row-styleWid {
    margin-right: 9rem;
    width: 65% !important;
  }

  .name-text {
    padding-left: 15px !important;
  }

  .marginLeft {
    margin-right: -25% !important;
  }

  .color-text {
    margin-left: 10px !important;
  }

  .section-title {
    font: normal normal 600 13px Poppins !important;
  }

  .btn-setting {
    width: 140px;
    height: 40px;
    padding: 0px !important;
  }

  .width-start-row {
    margin-left: 1rem;
  }

  .width-custom-row {
    width: 140% !important;
  }

  .width-custom-xs {
    font: normal normal 400 9px Poppins !important;
  }

  .width-description {
    max-width: 30% !important;
    font: normal normal 400 9px Poppins !important;
  }

  .width-custom-btn {
    width: 24% !important;
  }

  .height-30 {
    height: 20px !important;
    font: normal normal 400 9px Poppins !important;
    margin-left: 1.7rem;
    width: 4.5rem !important;
  }

  .dress-name {
    margin-left: -55px !important;
  }

  .small-width {
    width: 250% !important;
  }

  .width-selectAll {
    margin-left: -30px;
    margin-right: 30px;
  }

  .status-width {
    margin-left: -0.5rem;
  }

  .fit-sales {
    width: 50% !important;
  }

  .width-sales {
    width: 7rem !important;
  }
  .width-amount {
    width: 12rem !important;
  }

  .date-margin {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .grid-status {
    margin-left: 14px !important;
  }

  .width-subtitle-title {
    width: 33% !important;
  }

  .style-screen {
    justify-content: center !important;
  }

  .img-size {
    margin-left: 3rem !important;
  }

  .width-row-order {
    width: 30% !important;
  }

  .payment-check {
    max-width: 25% !important;
  }

  .paymentRow {
    max-width: 31% !important;
  }

  .PaymentBtn {
    max-width: 93vw !important;
  }

  .addressWidth {
    max-width: 20% !important;
  }

  .addresRow {
    max-width: 50% !important;
  }
  .modal-img {
    width: 12rem !important;
}
.modalDressDetails  table {
  margin-top: 2rem;
  margin-left: 3.2rem;
}
.scrollXOnSmallSize {
  overflow-x: hidden !important;
}
}

.address-container {
  margin-left: 4%;
}

.form-check-payment {
  width: fit-content !important;
}

.numberStylePay {
  width: 8rem !important;
}

@media (min-width: 376px) and (max-width: 435px) {
  .styleSum{
    margin-left: 2.7rem;
  }
  .titleSalesOrder{
    margin-left: 2.7rem !important;
  }
  .titleOrd{
    margin-left: 2rem !important;
  }
  .summaryPaymentWidthTab {
      margin-top: 0px !important;
    }
  .marginModal {
      margin-top: -10rem !important;
    }
  .mrgnTp{
    margin-top: 9rem !important;
  }
.scrolY {
    overflow-y: hidden !important;
  }
  .mrgnR{
    margin-right: -3rem !important;
  }
  /* .mrgnLeft{
    margin-left: -1.5rem !important;
  } */
  .toggler-icon {
    
    left: 30px !important;
  
  }
  .width-order{
    width: 37rem;
  }
  .marginDateLeft {
    margin-left: 0.5rem !important;
  }

  .mrgnstart {
    padding-left: 1.3rem !important;
  }
  .width-sales {
    width: 95px !important;
  }
  .width-salesStat {
    width: 1rem !important;
  }
  .mxwidthexp{
    max-width: 30rem !important;
  }

  .width-Inv {
    width: 40px !important;
  }

  .width-InvShip {
    margin-left: -1rem !important;
    width: 150px !important;
  }

  .navbar-toggler {
    top: 4rem !important;
    left: calc(50vw - 75px) !important;
    position: absolute !important;
  }

  .expoHr {
    width: 30.5rem !important;
  }
  .mxwidth {
    max-width: 35rem;
  }
  .marginAmountLeft {
    margin-left: 0.2rem;
  }

  .picWidth {
    width: 3.7rem !important;
  }
  .order-imageCustom {
    max-width: 90vw;
  }
  .mrginTopSum {
    margin-top: -3rem !important;
  }
  .date-inputOrder {
    width: 100% !important;
  }

  .paddat {
    margin-left: -3rem !important;
  }
  .date-payment {
    font-size: 12px !important;
    line-height: 20px;
  }
  .marginLeftOrder {
    margin-left: 2.5rem !important;
  }
  .widthLeft {
    margin: auto !important;
  }

  .numbering-container {
    width: fit-content !important;
  }
  .fontSize {
    font-size: 9px !important;
  }

  .widthOrd {
    width: 70vw;
  }
  .btn-setting {
    margin-right: -1.2rem !important;
    width: 50%;
  }
  .salesWidth {
    width: 125vw !important;
  }

  /* .widthAc {
    width: 115vw;
  } */

  .widthresp {
    width: 66vw !important;
  }

  .address-container {
    max-width: fit-content !important;
    margin-left: 3%;
    height: 20%;
  }

  .cardAddresWidth {
    width: 90vw;
  }

  .form-check-payment {
    margin-left: -1rem !important;
    margin-right: 1rem !important;
  }

  .btnadres {
    max-width: 10% !important;
    display: block !important;
  }
  .btnWidthSet {
    width: 19% !important;
  }

  .addressWidth {
    max-width: 20% !important;
  }

  .addresRow {
    margin-left: 2rem;
    max-width: 90% !important;
  }
  .addresRowHr {
    margin-left: 2.2rem;
    max-width: 94% !important;
  }

  .addresRowHeader {
    width: 100vw !important;
  }

 

  .margin-right-2 {
    margin-right: 0px !important;
  }
  .width-InvStatus {
    width: 77px !important;
  }
  .mrgnSta{
    margin-left: -6rem !important;
  }

  .searchForm {
    width: 70rem !important;
    margin-top: 1rem;

  }

  .toggle-style {
    padding-top: 50px !important;
  }

  .marginStart {
    margin-left: 2rem;
  }

  .marginStartShi {
    margin-left: 3.8rem;
  }
  .width-row-order {
    width: fit-content !important;
  }

  .width-row-sales {
 margin: auto !important;
  }
  .widthPag{
    margin-left: 10rem;
  }

  .width-row-header {
    width: 100% !important;
  }

  .btn-style-view {
    /* margin-left: 1rem; */
    margin-right: 0.5rem;
    width: 21.6rem !important;
  }

  .exportTrans {
    margin-left: -300px !important;
  }

  .hrTable {
    width: 32rem;
  }
  .hrInv {
    width: 130% !important;
  }

  .styleWidthImage {
    width: 33% !important;
  }

  .styleWidthImages {
    width: 95vw !important;
  }
  .invoiceWidth {
    width: 205vw !important;
  }
  .addressWidthBtn {
    width: 38rem !important;
  }

  .width-subtitle-payment {
    width: 280% !important;
  }

  .date-width {
    margin-left: -0.5rem !important;
  }
  .marg {
    padding-left: 40px !important;
  }

  .form-check-input2 {
    margin-left: -1rem !important;
    max-width: 20px !important;
  }

  .shipping-track {
    margin-left: -2rem !important;
  }

  .PaymentBtn {
    max-width: 60vw !important;
  }
  .paddingLeft {
    margin-left: 4.5rem;
  }

  .status-margin {
    margin-left: 4rem !important;
  }

  .marginLeftE {
    margin-left: 9rem;
  }

  .fit-width {
    width: 220% !important;
  }

  .width-start-row {
    width: 90vw !important;
  }

  .salesInvoice {
    margin-left: -20px;
  }

  .width-itemSales {
    width: 15% !important;
    font: normal normal normal 10px/18px Poppins !important;
  }

  .width-item {
    width: 18% !important;
    font: normal normal normal 10px/18px Poppins !important;
  }

  .settings-width-row {
    width: 48% !important;
  }

  .marginAc {
    width: 12% !important;
  }

  .widthDate {
    margin-left: -35px !important;
  }

  .widthInvoice {
    max-width: 20% !important;
  }

  .width-200 {
    width: 35rem !important;
  }
  .widthInv {
    width: 70rem;
  }
  .widthIn {
    width: 600px !important;
  }

  .fit-sales {
    width: 20% !important;
  }

  .width-po {
    margin-left: 2rem !important;
  }

  /* .styleWidthView {
    width: 90vw !important;
  } */

  .width-date {
    margin-left: -2rem !important;
  }

  .status-width {
    margin-left: 0rem !important;
  }

  .width-check {
    width: 2rem !important;
  }

  .fit-sales {
    width: 15% !important;
  }

  .fit-salesOrder {
    width: 30rem !important;
  }

  .small-width {
    width: 380% !important;
  }

  .grid-header {
    font: normal normal 600 9px/16px Poppins !important;
  }
  .grid-headerNew{
        font: normal normal 600 13px/16px Poppins !important;
  }

  .width-subtitle-title {
    max-width: 100% !important;
  }

  .row-transaction {
    max-width: 85vw !important;
  }

  .widthTran {
    width: 160% !important;
  }

  .sales-text {
    margin-left: -40px;
  }

  .sales-textTra {
    margin-left: -40px;
  }

  .marginTop {
    margin-top: -40px !important;
  }

  .status-text {
    margin-left: -2rem !important;
  }

  .settings-width {
    max-width: 20% !important;
  }
  .formWidth {
    max-width: 90vw !important;
  }
  .width-account {
    width: 200% !important;
  }

  .width-custom-row {
    width: 22.6rem !important;
  }

  .widtSta {
    width: 100vw !important;
  }

  .grid-image-checkout {
    margin-top: -7.5rem !important;
  }
  .grid-image-sum {
    margin-top: -13rem !important;
  }

  .margin-top-sum {
    margin-top: 1rem !important;
  }

  .widthSt {
    margin-left: 6rem;
  }

  .grid-eye-checkout {
    margin-top: -6.5rem !important;
  }

  .width-label {
    display: none !important;
  }

  .show-label {
    display: flex !important;
  }

  .fontInvoice {
    font-size: 10px !important;
  }

  .row-button {
    width: 91% !important;
    margin-right: 11.5rem !important;
    margin-left: -0.9rem !important;
  }

  .imgInvoice {
    margin-top: -18rem !important;
  }

  .invoice-eye {
    margin-top: -6rem;
    margin-left: -3rem;
  }
  .invoice {
    max-width: 26rem !important;
  }

  .row-button-purchase {
    width: 95% !important;
    margin-right: 12rem !important;
    margin-left: 0rem !important;
  }

  .row-buttonpay {
    width: 67vw !important;
  }

  .forgot-link {
    width: 130px !important;
    padding-left: 1.2rem !important;
    color: #cfbdab;
    text-transform: uppercase;
    font: normal normal normal 11px Poppins !important;
  }

  .collapsed {
    margin-left: 13.5rem !important;
  }

  .width-transaction {
    width: 5rem !important;
  }

  .dateText {
    margin-left: -3rem;
  }

  .amountCharge {
    width: 15% !important;
  }

  .width-transactionText {
    width: 28% !important;
  }

  .width-po {
    margin-left: 0rem !important;
    margin-right: 1.5rem;
  }

  .marginLeft {
    margin-right: -55% !important;
  }

  .toggle-input {
    margin-top: 1rem;
  }

  .styleWidthAddress {
    width: 103% !important;
  }

  .styleWidthInput {
    width: 110% !important;
  }

  .payment-check {
    max-width: 25% !important;
  }

  .row-styleWid {
    margin-right: 9rem;
    width: 70% !important;
  }

  .row-styleWidBtn {
    margin-right: 12.5rem;
    width: 85% !important;
  }
  .styleWidth {
    width: 32% !important;
  }

  .width90 {
    width: 90vw !important;
  }

  .width-amount {
    width: 12rem !important;
    margin-left: -4rem;
  }

  .hrSales {
    width: 90% !important;
  }
  .padRgth {
    margin-left: -1.5rem;
  }

  .row-styleWidth {
    width: 125% !important;
  }

  .row-styleWid {
    max-width: 65% !important;
  }

  .btn-style-row {
    margin-right: 5.5rem;
    width: 80% !important;
  }

  .row-style {
    padding: 0px !important;
  }

  .width-subtitle {
    max-width: 100vw !important;
  }

  .marginLeftEx {
    margin-left: -2rem;
  }

  .width-search {
    max-width: 70% !important;
  }

  .width-row {
    width: 85% !important;
    height: 50px !important;
  }

  .width-xs {
    width: fit-content !important;
  }

  .navbar-toggler:focus {
    box-shadow: none !important;
  }

  .sidebar-bg {
    margin-top: 1rem !important;
    background-color: #ffffff !important;
  }

  .desc-semiBold,
  .desc {
    font: normal normal 300 13px Poppins !important;
  }

  .margin-start {
    margin-left: -45px;
  }

  .link {
    font: normal normal 300 9px Poppins !important;
  }

  .btn-main {
    width: 93% !important;
    font: normal normal 300 11px Poppins !important;
    margin: auto;
  }

  .bride-img {
    height: 90% !important;
    width: 110% !important;
    object-fit: cover !important;
    margin: -80px 0px -290px 0px;
  }

  .login-bg {
    width: 100vw !important;
  }

  .style-screen {
    margin-top: -10px !important;
    padding-inline: 20% !important;
    padding-left: 18% !important;
  }

  .date-margin {
    margin-right: 3rem;
  }
  .width-Invdate {
    width: 65px !important;
  }
  .MarginLe {
    margin-left: 3.5rem;
  }

  .img-size {
    width: 105px !important;
    height: 46px !important;
    margin-top: -8%;
    text-align: center !important;
    margin-left: 5rem !important;
  }

  .input-style {
    max-width: 20rem !important;
  }

  .grid-image {
    height: 50px !important;
    width: 35px !important;
  }

  .grid-cell {
    width: 20% !important;
  }

  .settings-text {
    font: normal normal 600 8px Poppins !important;
    width: 12% !important;
  }

  .width-btn {
    width: fit-content !important;
    margin: auto !important;
  }

  .style-title {
    margin-left: 2rem !important;
  }

  .search {
    width: 32% !important;
  }

  .navbar {
    width: 85% !important;
  }

  .width-custom-xs {
    max-width: 20% !important;
  }

  .width-description {
    max-width: 20% !important;
  }

  .logoFont-small {
    background-color: transparent !important;
    width: fit-content !important;
  }

  .payment-button {
    width: 14rem !important;
  }

  .width-subtitle {
    width: 100% !important;
  }

  .paymen-hr {
    width: 138% !important;
  }
  .marginAut {
    text-align: center !important;
    margin: auto !important;
  }
  .width-export {
    width: fit-content !important;
  }
  .width-200Exp{
        width: fit-content !important;
        margin-left: -1.2rem !important;
  }

  /* .export-title {
    margin-left: -2rem !important;
  } */
  .padLeft {
    padding-left: 1.5rem !important;
  }

  .padding-export {
    margin-left: -16rem !important;
  }

  .padding-export-2 {
    margin-left: -10.5rem !important;
  }

  .marginNegative {
    margin-left: -100rem;
  }

  /* .padding-export-3 {
    margin-left: -2rem !important;
  } */

  .export-text {
    margin-left: -15rem !important;
  }

  .navbar-nav {
    width: 100% !important;
  }
  .widthLinks {
    width: 450% !important;
  }

  .paymentRow {
    width: 100% !important;
  }

  .paymentRowNew {
    width: 90vw !important;
  }

  .width-itemInvoice {
    width: 8% !important;
  }

  .marginInv {
    margin-left: 3rem;
  }

  .shipping-trackInv {
    width: 30% !important;
  }

  .widthInvoiceList {
    width: 90vw !important;
  }

  .hideTitle {
    display: none;
  }

  .poStyle {
    margin-left: -3.5rem !important;
  }

  .widthSummary {
    width: 150%;
  }

  .Marg {
    margin-left: -1rem;
  }
  .fontSize {
    font-size: 9px;
  }
  .gap-2 {
    margin-right: -1rem !important;
  }
  .btn-setting {
    margin-left: 2rem;
    font-size: 9px !important;
    padding-inline: 15px !important;
  }

  .scrollX {
    overflow-x: hidden !important;
  }
   

  .width150 {
    width: 27rem !important;
  }

  .scrol {
    overflow-x: auto !important;
    scroll-behavior: auto !important;
  }

  .invoice {
    max-width: 60rem;
  }
  .widthAdd {
    max-width: 190vw;
  }

  .exportwi {
    width: 30rem !important;
  }
  .export2 {
    width: 30rem !important;
  }
  .exp {
    margin-right: -8rem !important;
    width: 30rem !important;
  }
  .maxwidthSales {
    max-width: 33rem !important;
  }
  .widthAddres {
    max-width: 160vw !important;
  }
  .width-amountCha {
    margin-right: 2.2rem !important;
    width: 1rem !important;
    margin-left: -3.5rem;
  }
  .statusPad {
    padding-left: 0.5rem !important;
  }
  .widthButnns {
    margin-left: 1rem !important;
    max-width: 19% !important;
  }

  .widthHrControl {
    margin-left: 2rem;
    max-width: 94% !important;
  }
     .mrgnRght {
       width: fit-content !important;
       margin-left: -1.5rem !important;
     }
         .navbar-toggler:not(.collapsed) {
           position: fixed !important;
         }
                 .fixPosition {
                   position: fixed !important;
                   left: -15px;
                 }
  
}

@media (min-width: 426px) and (max-width: 767px) {
  .btn-main {
    width: 100% !important;
    margin: auto !important;
    font: normal normal 300 11px Poppins !important;
  }
}

.navbar-toggler,
.navbar-toggler:focus,
.nav-bar-toggler:active,
.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
  border: 0;
}

.navbar-toggler span {
  margin: 0;
  padding: 0;
}

@media only screen and (width: 428px) {
  /* .mrgnTop {
    margin-top: -1rem !important;
  } */
}
@media only screen and (width: 425px) {
  .row-button {
    width: 105% !important;
    margin-right: 11.5rem !important;
    margin-left: -0.9rem !important;
  }

  .row-button-purchase {
    width: 91% !important;
  }
    .scrolY {
      overflow-y: hidden !important;
    }
}

.toggler-icon {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 1px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  background: #43425d;
}

.middle-bar {
  margin-top: 0px;
}
.justify-content-around {
  justify-content: space-around !important;
}

.navbar-toggler .top-bar {
  margin-top: 0px !important;
  transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
  opacity: 0 !important;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  margin-top: 0px !important;
  transform: rotate(-135deg);
}

.navbar-toggler.collapsed .top-bar {
  margin-top: -15px !important;
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
  margin-top: 0px !important;
  opacity: 1 !important;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  margin-top: 15px !important;
  transform: rotate(0deg);
}

::placeholder {
  font: normal normal normal 12px/15px Poppins !important;
  color: #afafaf !important;
}

.btn-main {
  background: #43425d 0% 0% no-repeat padding-box !important;
  border: 1px solid #43425d !important;
  border-radius: 4px !important;
  font: normal normal medium 13px/20px Poppins;
  padding-inline: 35px !important;
  color: #ffffff !important;
  text-transform: uppercase;
  width: 139px;
  height: 38px;
}

.btn-secondary {
  background: #b8998d 0% 0% no-repeat padding-box !important;
  border: 1px solid #b8998d !important;
  border-radius: 4px !important;
  font: normal normal 400 12px/18px Poppins;
  padding-inline: 20px !important;
  min-height: 30px;
  height: 45px;
  max-height: 3rem;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  color: #ffffff !important;
  text-transform: uppercase;
}

.height-30 {
  height: 35px !important;
  font: normal normal 600 12px/18px Poppins !important;
}

.btn-2 {
  background: #b8998d 0% 0% no-repeat padding-box !important;
  border: 1px solid #b8998d !important;
  border-radius: 4px !important;
  font: normal normal 400 12px/18px Poppins !important;
  padding-inline: 25px !important;
  min-height: 30px;
  max-height: 3rem;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  color: #ffffff !important;
  text-transform: uppercase;
}

.btn-add {
  background: #b8998d 0% 0% no-repeat padding-box !important;
  border: 1px solid #b8998d !important;
  border-radius: 4px !important;
  font: normal normal 400 12px/18px Poppins !important;
  padding-inline: 25px !important;
  min-height: 30px;
  max-height: 3rem;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  color: #ffffff !important;
  text-transform: uppercase;
  border-radius: 25px !important;
  width: 8rem !important;
  margin-left: 2rem !important;
  margin-bottom: -20px !important;
}

.btn-add-store {
  background: #b8998d 0% 0% no-repeat padding-box !important;
  border: 1px solid #b8998d !important;
  border-radius: 4px !important;
  font: normal normal 400 12px/18px Poppins !important;
  color: #ffffff !important;
  text-transform: uppercase;
  border-radius: 25px !important;
  width: fit-content !important;
}

.btn-secondary:focus {
  box-shadow: none !important;
}

.no-border {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
  border-radius: inherit !important;
}

.form-check-label {
  font: normal normal 300 14px/25px Poppins;
  letter-spacing: 0px;
  color: #43425d;
}

.link-pass {
  background: transparent;
  border: transparent;
  text-decoration: none !important;
  font: normal normal normal 13px/18px Poppins;
  letter-spacing: 0px;
  color: #cfbdab;
  text-transform: uppercase;
  cursor: pointer;
}

.link {
  background: transparent;
  border: transparent;
  text-decoration: none !important;
  font: normal normal normal 14px/18px Poppins !important;
  letter-spacing: 0px;
  color: #cfbdab;
  text-transform: uppercase;
  cursor: pointer;
}

.header-bg {
  background: #fcfcfc 0% 0% no-repeat padding-box;
  padding-top: 15px;
  padding-bottom: 10px;
}

.header-bg-modal {
  padding-top: 15px;
  padding-bottom: 10px;
}

.title-modal {
  font: normal normal 600 18px/60px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0;
  line-height: normal;
}

.subTitle-modal {
  font: normal normal normal 12px/21px Poppins;
  color: #000000;
  opacity: 0.45;
}

/* Sidebar */
.circle-small {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  /* background: #cfbdab 0% 0% no-repeat padding-box; */
  background-image: url(./assets/img/logo.jpeg);
  background-size: cover;
}

.sidebar-svg {
  width: 25px;
  margin-right: 15px;
  margin-left: 15px;
}

.logoFont-small {
  max-width: fit-content !important;
  font: normal normal normal 22px/29px aliens and cows;
  color: #43425d;
}

.sidebar-size {
  width: 1rem !important;
}

.sidebar-bg {
  background-color: #f1f1f1;
}

.links li {
  list-style: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 1rem;
}

.links li.active {
  background-color: #ffffff;
}

.links a {
  text-decoration: none;
  font: normal normal 600 12px/25px Poppins;
  letter-spacing: 0px;
  color: #43425d;
  text-transform: uppercase;
}

.links {
  padding-left: 0px !important;
  height: 100vh;
}

.navitem:global(.active) {
  background-color: #777777;
}

.title {
  font: normal normal 600 18px/60px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0;
  line-height: normal;
}

.section-title {
  margin-bottom: 0;
  line-height: normal;
  font: normal normal 600 16px/60px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.subTitle {
  font: normal normal normal 12px/21px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.45;
}

.white-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 45px 30px;
  width: 75%;
}

.white-container-p0 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 75%;
}

.searchForm .form-control {
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
}

.searchForm .btn-main {
  border-top-left-radius: unset !important;
  border-bottom-left-radius: unset !important;
}

.column-text {
  font: normal normal 600 13px/20px Poppins;
  letter-spacing: 0px;
  color: #767676;
  text-transform: uppercase;
}

.react-bootstrap-table .table {
  border-collapse: separate !important;
  border-spacing: 5px;
}

.table > :not(:first-child) {
  border-top: none !important;
}

div .btn-margin {
  margin-right: 3%;
}

label {
  font: normal normal 600 10px/16px Poppins !important;
  opacity: 0.7 !important;
}

.label-text {
  color: #767676;
  font: normal normal 600 10px/16px Poppins !important;
  opacity: 0.7 !important;
}

.tab {
  color: white !important;
  background-color: #b8998d;
}

.nav-link {
  color: white !important;
  background-color: #b8998d !important;
  border-radius: 0 !important;
  margin-right: 0.5rem;
  opacity: 0.5;
}

.nav-link.active {
  background-color: #b8998d !important;
  opacity: 1;
}

.border {
  border-radius: 5px;
}

.backToList {
  margin: 4rem 0 2rem 0 !important;
}

.grid-eye {
  transform: translateX(-77%);
  cursor: pointer !important;
}

.summary-header {
  font: normal normal normal 18px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.5;
  margin: 3rem 0 1rem 0 !important;
}

@media only screen and (max-width: 600px) {
  .white-container {
    width: 100%;
  }
}

.center {
  height: 100vh;
  position: relative;
  top: 0;
  margin: 0;
}

.btn-close {
  color: #b8998d !important;
  box-shadow: none !important;
}

.btn-close:hover {
  background-color: #ffffff !important;
  color: #b8998d !important;
}

.modal-header {
  padding: 0.5rem 0.5rem;
  border: none !important;
}

.modal-content {
  border: none !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 26px #0000000d;
  margin: 0;
  width: 100% !important;
}

.modal-dialog {
  opacity: 1 !important;
}

@media (min-width: 576px) {
  .toggle-style {
    display: flex !important;
  }

  .modal-dialog {
    margin: 0.9rem auto !important;
  }
}

.error-icon {
  margin: 0 auto;
  position: relative;
  left: 48%;
}

.error-message {
  margin: 0 auto;
  position: relative;
  left: 47%;
}

.error-item {
  font: normal normal 18px/60px Poppins;
  padding-bottom: 45px;
}

.error-text {
  position: absolute;
  top: 54%;
  font: normal normal normal 12px/18px Poppins;
  color: #000000;
  padding-inline: 9% !important;
  opacity: 0.45;
}

.error-date {
  position: absolute;
  padding-inline: 30px !important;
}

.error-span {
  font: normal normal bold 12px/18px Poppins;
}

.auto-modal {
  height: auto;
  padding: 1.25rem;
}

.form-dialog {
  max-width: 60rem !important;
}

div .adduseraccount {
  max-width: 900px !important;
  height: auto;
}

.btn-form {
  margin-top: -3rem !important;
}

.form-control {
  padding: 0.75rem 0.75rem !important;
}

.modal-footer {
  border: none !important;
  padding-left: 3%;
  padding-right: 3%;
}

.form-index {
  position: relative;
}

.modal-index {
  position: absolute;
  z-index: 4;
  margin-right: 0;
}

.section-title {
  margin-bottom: 0;
  line-height: normal;
  font: normal normal 600 16px/60px Poppins;
  color: #000000;
}

.user-txt {
  margin-top: -1.5%;
  position: relative;
}

.user-msg {
  font-size: 12px;
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.45;
}

.user-grp {
  column-gap: 1%;
  column-count: 2;
}

.user-svg {
  vertical-align: 0 !important;
}

.vieworder-nbr {
  font: normal normal bold 18px/27px Poppins;
  letter-spacing: 0px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;
}

.vieworder-name {
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #767676;
}

.vieworder-cost {
  font: normal normal 900 18px/27px Poppins;
  letter-spacing: 0px;
  color: #b8998d;
}

.vieworder-color {
  font: normal normal 600 10px/16px Poppins;
  letter-spacing: 0px;
  color: #767676;
  text-transform: uppercase;
  opacity: 1;
}

.grid-chevron {
  margin-left: 10px;
  width: 8px;
  height: 5px;
}

.grid-image {
  background: transparent 0% 0% no-repeat padding-box;
  /* height: 4rem; */
  width: 46px;
  height: 69px;
  position: relative !important;
}

.grid-row-border {
  border-style: solid;
  border-width: 1px;
  border-color: rgba(112, 112, 112, 0.15) !important;
  margin-top: 15px;
  height: auto;
  font-size: 12px;
}

.grid-cell {
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: #1a1a1a;
  text-transform: uppercase;
}

.box-shadow-none {
  box-shadow: none !important;
}

.box-shadow-none {
  box-shadow: none !important;
}

.vieworder-box-color {
  border: 1px solid #dddddd;
  border-radius: 5px;
  width: 108px;
  height: 45px;
  background-color: white;
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: #afafaf;
  text-align: center;
  opacity: 1;
}

.vieworder-box-color:focus {
  border: 1px solid #b8998d;
  display: block;
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: #43425d;
  opacity: 1;
}

.vieworder-box-size {
  width: 45px;
  height: 45px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e7e6e9;
  border-radius: 5px;
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: #afafaf;
  opacity: 1;
}

.vieworder-box-size:focus {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b8998d;
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: #43425d;
}

.vieworder-estimated {
  width: 129px;
  height: 26px;
  background: #767676 0% 0% no-repeat padding-box;
  opacity: 1;
  display: block;
}

.vieworder-estimated-txt {
  font: normal normal 600 10px/16px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  padding-inline: 11px;
}

.vieworder-estimated-date {
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: #43425d;
}

.arrow {
  text-decoration: none;
  text-transform: uppercase;
  color: #b8998d;
}

.back-to-list {
  font: normal normal 600 12px/18px Poppins;
  letter-spacing: 0px;
  color: #b8998d;
  text-transform: uppercase;
}

.form-check {
  margin-bottom: 0px !important;
}

.form-check-input {
  border: 1px solid #e7e6e9 !important;
  width: 1.1rem !important;
  height: 1.1rem !important;
}

.form-check-input:focus {
  border: 1px solid #e7e6e9 !important;
  box-shadow: none !important;
}

.date-input::placeholder {
  font: normal normal normal 12px/15px Poppins !important;
}

.form-check-input2:checked[type='checkbox'] {
  background-image: url(./assets/img/check.svg) !important;
  background-color: #ffffff !important;
  /* border: 1px solid rgba(0, 0, 0, 0.25) !important;*/
  background-size: 9px;
}

.form-check-input {
  border: 1px solid #e7e6e9 !important;
  width: 1.1rem !important;
  height: 1.1rem !important;
}

.form-check-input:focus {
  border: 1px solid #e7e6e9 !important;
  box-shadow: none !important;
}


.form-check-input:checked[type='radio'] {
  background-color: #b8998d !important;
}

.numbering-container {
  background: rgba(212, 189, 180, 0.28) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 26px #00000017;
  border-radius: 1.5rem;
  width: fit-content !important;
}

.accordion-title {
  font: normal normal normal 16px/25px Poppins;
  color: #43425d;
  text-transform: capitalize;
  cursor: pointer;
}

.accordion-subtitle {
  font: normal normal normal 12px/18px Poppins;
  color: #767676;
  opacity: 0.45;
  cursor: pointer;
}

.header-summary {
  margin-top: 5rem !important;
  margin-left: 4rem !important;
}

.circle-summary {
  width: 57px;
  height: 57px;
  background: #cfbdab 0% 0% no-repeat padding-box;
}

.txt-summary {
  font: normal normal normal 34px/29px aliens and cows;
  color: #43425d;
  opacity: 1;
}

.sales-order {
  font: normal normal normal 30px/13px Poppins;
  color: #000000;
  opacity: 0.5;
}

.hr-summary {
  margin-left: 6rem !important;
  margin-right: 6rem !important;
  border: 1px solid #dddddd;
}

.info-summary {
  margin-top: 1rem !important;
  margin-left: 4rem !important;
}

.info-summary-name {
  font: normal normal normal 14px/20px Poppins;
  letter-spacing: 0px;
  color: #4d4f5c;
  opacity: 0.5;
}

.info-summary-sales {
  font: normal normal normal 14px/20px Poppins;
  letter-spacing: 0px;
  color: #767676;
  opacity: 1;
}

.info-summary-nbr {
  font: normal normal normal 14px/20px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.border-grid-summary {
  margin-right: 6rem !important;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dddddd;
  border-radius: 10px;
  opacity: 1;
}

.ship-summary {
  margin-left: 4rem !important;
}

/* end */
.bill-summary {
  font: normal normal bold 14px/20px Poppins;
  letter-spacing: 0px;
  color: #4d4f5c;
}

.bill-info {
  text-align: left;
  font: normal normal normal 14px/20px Poppins;
  letter-spacing: 0px;
  color: #4d4f5c;
}

/* plus */
.border-grid-summary {
  margin-right: 6rem !important;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dddddd;
  border-radius: 10px;
  opacity: 1;
}

/* end*/
.border-summary {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dddddd;
  border-radius: 10px;
  opacity: 1;
}

.summary-txt {
  font: normal normal 600 13px/20px Poppins;
}

.back-to-list {
  font: normal normal 600 12px/18px Poppins;
  color: #b8998d;
  text-transform: uppercase;
  text-decoration: none;
}

.back-arrow {
  margin: 2rem 0 2rem 0 !important;
}

.hr-summary-border {
  margin-left: 3.1rem !important;
  margin-right: 4.6rem !important;
  border: 1px solid #dddddd;
}

.summary-sol {
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #1a1a1a;
  text-transform: uppercase;
  opacity: 1;
}

.summary-container {
  background: transparent 0% 0% no-repeat padding-box;
  background-image: linear-gradient(#ffffff00 55%, #e1e5e84d 45%);
}

.Homeimage {
  object-fit: cover;
  position: fixed;
  z-index: -1;
  opacity: 0.1;
  transform: matrix(0, -1, 1, 0, 0, 0);
}

.border-summary-margin {
  margin-top: 5rem !important;
}

.modal-total-summary {
  margin-top: 18rem !important;
  width: 5rem !important;
}

/* plus */
.modal-total-summary-sales {
  margin-top: 18rem !important;
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
}

.border-total {
  width: 316px !important;
  height: 140px !important;
  margin-right: 3rem !important;
  background: #f5f7f9 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}

.total-txt {
  font: normal normal normal 18px/27px Poppins;
  letter-spacing: 0px;
  color: #1a1a1a;
  text-transform: uppercase;
  opacity: 1;
}

.total-nbr {
  font: normal normal bold 20px/30px Poppins;
  letter-spacing: 0px;
  color: #1a1a1a;
  text-transform: uppercase;
  opacity: 1;
}

.modal-total-summary-list {
  margin-top: 18rem !important;
  width: 100%;
  height: 140px;
  background: #f5f7f9 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin-left: 2rem;
}

.modal-total-invoice-list {
  margin-top: 18rem !important;
  width: 57vw !important;
  height: 140px;
  background: #f5f7f9 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}

.modal-total-invoice-total {
  margin-top: 18rem !important;
  width: 316px !important;
  height: 140px;
  background: #f5f7f9 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.total-nbr-list {
  font: normal normal normal 18px/27px Poppins;
  color: #1a1a1a;
  text-transform: uppercase;
}

.total-amount-list {
  font: normal normal bold 20px/30px Poppins;
  color: #1a1a1a;
  text-transform: uppercase;
}

.summary-txt-amnt {
  font: normal normal 600 13px/20px Poppins;
  color: #767676;
  text-transform: uppercase;
  padding-left: 3rem !important;
}

.invoice-box {
  border: 1px solid #dddddd;
  border-radius: 5px;
  font: normal normal normal 12px/18px Poppins;
  color: #afafaf;
  text-transform: uppercase;
  background-color: white;
}

.invoice-box:focus {
  background: #767676 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font: normal normal normal 12px/18px Poppins;
  color: #ffffff;
}

.grid-cell-invoice {
  font: normal normal normal 12px/18px Poppins !important;
  color: #b8998d !important;
  text-decoration: none !important;
}

.grid-cell-date {
  font: normal normal normal 12px/18px Poppins;
  color: #43425d;
}

.grid-cell-status {
  font: normal normal normal 12px/18px Poppins;
  color: #43425d;
  padding-left: 2rem !important;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  margin-left: 2px !important;
}

.btn-flesh {
  background: #d4bdb4 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 26px #00000017;
  border-radius: 100px;
  opacity: 0.1;
}

.btn-arrow {
  background: #43425d 0% 0% no-repeat padding-box;
  opacity: 1;
}

.btn-nbr {
  font: normal normal medium 12px/18px Poppins !important;
  letter-spacing: 0px;
  color: #767676;
}

.card {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #dddddd !important;
}

.card-info {
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: #afafaf;
}

.z-index2 {
  z-index: 2;
  top: 0;
  left: 0;
}

.absolute-pos {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.card-header {
  border-bottom: none !important;
}

.card-body {
  padding-top: 0px !important;
  padding-right: 1rem;
}

.card-number {
  border: 1px solid #dddddd;
  border-radius: 50px !important;
  width: 32px !important;
  height: 32px !important;
  text-align: center !important;
}

.card-number p {
  font: normal normal normal 14px/21px Poppins;
}

.content-box {
  top: 50px;
}

.accordion-title {
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #43425d;
  text-transform: capitalize;
  cursor: pointer;
}

.accordion-subtitle {
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: #767676;
  opacity: 0.45;
  cursor: pointer;
}

.invoices-titles {
  font: normal normal 600 10px/16px Poppins;
  letter-spacing: 0px;
  color: #767676;
  text-transform: uppercase;
  opacity: 1;
}

.address-container {
  border: 1px solid #b8998d !important;
  border-radius: 4px;
  color: #b8998d !important;
}

.card-header {
  border-bottom: none !important;
}

.btn-add-address:hover {
  color: #b8998d !important;
}

.btn-address {
  position: relative;
  left: 40px;
}

.hrAccordion {
  width: 93% !important;
}

@media (min-width:700px) and (max-width: 1024px) {
  
  .marginAmountLeft {
    margin-left: 0.5rem !important;
  }
  .cstmrgn {
    margin-left: 1.5rem;
  }
  .exportTrns {
    margin-left: -2rem;
  }
  .widthTransc {
    max-width: 12rem;
  }
  .marginStartShi {
    margin-left: 1rem;
  }
  .widthTr {
    margin-left: 0.8rem !important;
  }
  .mrgnRght {
    margin-left: 1.3rem;
  }
  .moveToLeft {
    margin-left: -2rem;
  }

  .button-smaller {
    width: 44rem !important;
  }

  .row-Pay {
    width: 300% !important;
  }

  .navbar-toggler {
    display: flex !important;
  }

  .width-pay {
    width: 400% !important;
  }
 
  .bride-img2 {
    margin: -350px 0px -350px 0px;
    width: 100vw !important;
    height: auto !important;
  }

  .login-bg-new {
    overflow: hidden !important;
    height: 50% !important;
  }

  .style-screen-new {
    margin-top: 2rem !important;
    padding-inline: 20% !important;
   
  }

  .input-lineBorder-new {
    margin-top: 1.5rem !important;
  }

  .login-bg {
    overflow-y: hidden !important;
    width: 100% !important;
  }

  .bride-img {
    width: 100vw !important;
    margin: -350px 0px -350px 0px;
  }

  .style-screen {
    padding-inline: 12rem !important;
  }

  .widthPay {
    width: 10% !important;
  }

  .widthPayVisa {
    max-width: 5% !important;
  }

  .settings-width-row {
    width: 113%;
  }

  .settings-width {
    max-width: 20% !important;
  }

  .width-transactionText {
    width: 10% !important;
  }

  .sales-textTra {
    margin-left: 11rem;
  }

  .amountBi {
    margin-left: 12rem;
  }

  .widthAm {
    width: 20% !important;
  }

  .exportwi {
    margin-right: 1.5rem !important;
    width: fit-content !important;
  }

  .btnEx {
    padding-left: 5rem !important;
  }

  .marginExpor {
    margin-left: -4rem !important;
  }

  .flex-title {
    align-items: flex-end;
    justify-content: space-between;
  }

  .button-eye {
    background: white;
    border: none;
  }

  .round-gray-border-btn {
    background: inherit;
    border: 1px solid #c9c8ea;
    border-radius: 1.5rem;
    text-align: center;
    min-width: 106px;
    min-height: 47px;
    color: #c9c8ea;
  }

  .purple-round-btn {
    background: #c9c8ea;
    border: none;
    border-radius: 1.5rem;
    text-align: center;
    min-width: 106px;
    min-height: 47px;
    color: #fff;
  }

  .light-purple-round-btn {
    background: #b8998d 0 0 no-repeat padding-box;
    border: none;
    border-radius: 1.5rem;
    color: #fff;
    font-size: 10px;
    min-width: 79px;
    min-height: 26px;
  }
  .button-payment2 {
width: 100% !important;
  }

  .SidebarNavw {
    width: 100vw !important;
  }

  .hrAccordion {
    width: 82% !important;
  }

  .hrTable {
    width: 82.5% !important;
  }

  .hideSide:not(.show) {
    visibility: hidden !important;
    width: 100vw !important;
    max-height: 5rem !important;
    background-color: #ffffff !important;
  }

  .widthTab {
    width: 100vw !important;
  }

  .widthTabletSalesOrderSummary {
    width: 100% !important;
  }

  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
  }

  .sidebar-bg {
    background-color: #ffffff;
  }

  .marginLeft {
    margin-right: -32rem !important;
  }

  .marginLeft .collapsed {
    margin-right: 33rem !important;
  }

  .collapsed {
    margin-top: -2rem !important;
  }

  .magrinRight {
    margin-right: 1rem;
  }

  .grid-row-border-order {
    width: 170% !important;
  }

  .widthAddress {
    width: 170% !important;
  }

  .paymentRow {
    width: 170% !important;
  }

  .payment-button {
    width: 100% !important;
  }

  .rowWidth {
    width: 200% !important;
  }

  .width-label {
    width: 15% !important;
  }

  .widthColor {
    width: 22% !important;
  }

  .width150 {
    width: 170% !important;
  }

  .exportTrans {
    width: 170% !important;
    padding-right: 9rem !important;
  }

  .widthInv {
    width: 115% !important;
  }

  .width-200 {
    width: 110% !important;
  }
  .paddingStatusTab {
    margin-left: 0.5rem !important;
  }

  .settings-text {
    width: 19% !important;
  }

  .widtSet {
    width: 122% !important;
  }

  .widthTran {
    width: 89vw !important;
  }

  .row-transaction {
    width: 87vw !important;
  }

  .padding-export {
    margin-left: -3.2rem;
  }
  .mrgnrght {
    margin-right: 0.2rem !important;
  }
  .scrollXOnTablet {
    overflow-x: hidden !important;
  }

  .row.grid-header.me-5.ps-3.fit-sales.small-width.width150 {
    width: 94vw !important;
  }
  .grid-row-border {
    width: 94vw;
  }
  .boxWidthTab {
    width: 78vw !important;
  }
  .marginOnStatus {
    margin-left: 2rem !important;
  }
  .card {
    width: 96vw;
  }
  .width-subtitle-payment {
    width: 74.2vw;
  }
  .widthPaymentOnTablet {
    width: 90.3vw !important;
  }

  .hrInv {
    width: 102% !important;
  }
  .expoHr {
    width: 102% !important;
  }
  .mrgnLftSta {
    margin-left: auto !important;
  }
  .statusMrgn {
    margin-left: 1.5rem !important;
  }
    .btn-main-new {
      width: 100% !important;
      background-color: #b8998d !important;
      border-radius: 50px !important;
      margin-top: 2rem !important;
      border: none !important;
    }
  
    .btnTabRight {
      margin-right: -4.5rem;
    }
  
    .mrgnTab {
      margin-left: 3rem;
    }
  
    .date-width {
      margin-left: 1.5rem;
    }
  
    .width-Inv {
      width: fit-content !important;
    }
  
    .form-check-input2 {
      width: 20px !important;
    }
  
    .displayVisible {
      display: block !important;
      width: 85vw;
    }
  
    .btn {
      padding: 0.375rem -1rem !important;
    }
  
    .widthHrControl {
      width: 92.5% !important;
    }
  
    .summaryPaymentWidthTab {
      width: 84vw !important;
    }
  
    .widthAddressTab {
      width: 84vw !important;
    }
  
    .rowWidth {
      width: 107vw !important;
    }
  
    .widthLabelTab {
      width: 13vw !important;
    }
  
    .spaceAroundOnTab {
      justify-content: space-around;
    }
  
    .maxWidthTab {
      max-width: 100vw !important;
    }
  
    .widthOrderHeaderTab {
      width: 98vw !important;
    }
  
    .widthSalesOrderOnTab {
      width: 15.4% !important;
    }
  
    .widthTitleTab {
      width: 15% !important;
    }
  
    .widthSummary {
      width: 90vw !important;
    }
  
    .widthTitlesSummaryTab {
      width: 96vw !important;
    }
  
    .maxWidthTablet {
      width: 112% !important;
      max-width: 120% !important;
    }
  
    .grid-image {
      height: 50px !important;
      width: 35px !important;
    }
  
    .borderInvoiceWidthTab {
      width: 78vw !important;
    }
  
    .widthDetailsInvoice {
      width: 11% !important;
    }
  
    .invoice-eye {
      margin-top: 3.7rem;
      margin-left: -3rem;
    }
  
    .paddingToLeftTab {
      padding-left: 8px;
    }
  
    .modal-img {
      width: 21rem !important;
    }
  
    .modalDressDetails table {
      margin-left: 3.2rem;
  }
}

.payment-container {
  border: 1px solid #dddddd;
  border-radius: 4px;
  font: normal normal normal 12px/18px Poppins;
  align-items: center;
  align-items: center !important;
  padding-top: 0.6rem !important;
  padding-bottom: 0.6rem !important;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
  /* width: inherit !important; */
}

.purchaseorder-label {
  font: normal normal 600 10px/16px Poppins;
  color: #767676 !important;
  text-transform: uppercase;
}

.purchaseorder-input {
  border: 1px solid #dddddd;
  border-radius: 5px !important;
  opacity: 1;
  font: normal normal normal 12px/18px Poppins;
  width: 40% !important;
}

.purchaseorder-input:active,
.purchaseorder-input:focus {
  border: 1px solid #b8998d !important;
  box-shadow: none !important;
  letter-spacing: 0px;
  color: #43425d;
}

.purchaseorder-option {
  width: 100% !important;
  font: normal normal normal 12px/18px Poppins !important;
  color: #43425d !important;
  padding: 0.75rem 0.25rem 0.875rem 0.85rem !important;
}

.order-summary-cost {
  font: normal normal 600 12px/23px Poppins;
  letter-spacing: 0px;
  color: #43425d;
}

.next-btn {
  width: 11rem;
}

.paymentLogoContainer {
  width: 40px;
  height: 12px;
}

.second-title {
  font: normal normal 600 18px/60px Poppins;
  color: #000000;
  opacity: 0.5;
  line-height: normal;
  padding-bottom: 10px;
}

.paragraph {
  opacity: 0.3;
  font-size: 12px;
}

.details-header {
  text-align: left;
  font: normal normal 600 12px/16px Poppins;
  letter-spacing: 0px;
  color: #767676;
  text-transform: uppercase;
  opacity: 1;
  margin-bottom: 0;
  margin-top: 2rem;
}

.sales-summary-cost {
  font: normal normal 900 18px/27px Poppins;
  letter-spacing: 0px;
  color: #43425d;
}

.summary-btn {
  width: 100% !important;
  height: 4rem;
  margin: 1rem 0 1rem 0;
}

.cost {
  color: #43425d;
}

.invoice-eye {
  position: relative !important;
  transform: translate(180%, -220%) !important;
}

.summary-title {
  font: normal normal normal 18px Poppins;
  color: #000000;
  opacity: 0.5;
  text-transform: capitalize;
  padding-top: 1rem !important;
  font-size: 1rem;
}

.summary-text {
  font: normal normal normal 13px Poppins;
}

.head-container {
  margin-top: 1rem;
  height: 30rem;
  width: 100%;
  padding: 0 !important;
}

/* plus */
.option-default {
  font: normal normal normal 12px/18px Poppins !important;
  letter-spacing: 0px;
  color: #afafaf !important;
  opacity: 0.5 !important;
}

@media only screen and (max-width: 598px) {
  .border-total {
    margin-right: 0rem !important;
  }
}

.grid-row-visa {
  display: flex;
}

@media only screen and (max-width: 480px) {
  .grid-row-visa {
    display: inline;
  }
}

.succes-text {
  position: absolute;
  top: 54%;
  font: normal normal normal 12px/18px Poppins;
  color: #000000;
}

button:disabled {
  opacity: 0.5 !important;
}

.btn-saveInfo {
  width: fit-content !important;
}

.btn-left {
  margin-right: 3.5rem !important;
}

.height37 {
  height: 37px;
}

.pagination_num {
  font: normal normal normal 12px/18px Poppins;
  letter-spacing: 0px;
  color: #767676;
  text-decoration: none !important;
}

.grid-eye-order {
  transform: translateX(-50%);
}

@media only screen and (max-width: 1352px) {
  .grid-eye-order {
    transform: translateY(-200%);
    margin-left: 66%;
  }

  .grid-row-border-order {
    height: 4.1rem !important;
  }
}

@media only screen and (max-width: 1230px) {
  .grid-eye-order {
    transform: translateY(-200%);
    margin-left: 85%;
  }
}

@media only screen and (max-width: 1160px) {
  .grid-eye-order {
    transform: translateY(-200%);
    margin-left: 80%;
  }
}

@media only screen and (max-width: 1078px) {
  .grid-row-border-order {
    height: auto !important;
  }
}

@media only screen and (max-width: 1060px) {
  .grid-eye-order {
    transform: translateY(-200%);
    margin-left: 86%;
  }

  .grid-row-border-order {
    height: auto !important;
  }
}

@media only screen and (max-width: 991px) {
  .grid-eye-order {
    transform: translateY(0%);
    margin-left: 0%;
    transform: translateX(-50%);
  }

  .head-container {
    height: 32rem !important;
  }
}

.grid-row-border-orderNew{
  border-style: solid;
    border-width: 1px;
    border-color: rgba(112, 112, 112, 0.15);
    margin-top: 15px;
    height: auto;
    font-size: 12px;
}
.grid-row-border-order {
  border-style: solid;
  border-width: 1px;
  border-color: rgba(112, 112, 112, 0.15);
  margin-top: 15px;
  height: auto;
  font-size: 12px;
}

.hrAccordionOrder {
  width: 100% !important;
}

.hrAccordionrP {
  width: 89% !important;
  margin-left: 1rem !important;
}

.btn-setting:focus {
  box-shadow: none !important;
}

.margin-right-new {
  margin-right: 4px !important;
}

.error {
  color: #707070 !important;
  font: normal normal normal 12px/18px Poppins !important;
}

.eye-btn {
  border: none;
  background-color: transparent;
}
.search-button {
  border: none !important;
  background-color: none !important;
  border-color: transparent !important;
}

asc {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #b8998d;
  color: #b8998d;
  box-shadow: 9999px 0 0 -5px #b8998d;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
  z-index: 10;
}

.dot-pulse::before,
.dot-pulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #b8998d;
  color: #b8998d;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #b8998d;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #b8998d;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #b8998d;
  }

  30% {
    box-shadow: 9984px 0 0 2px #b8998d;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #b8998d;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #b8998d;
  }

  30% {
    box-shadow: 9999px 0 0 2px #b8998d;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #b8998d;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #b8998d;
  }

  30% {
    box-shadow: 10014px 0 0 2px #b8998d;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #b8998d;
  }
}

.loading-center {
  text-align: center !important;
  margin: 0 auto !important;
}

.examples .snippet:before {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 5px;
  content: attr(data-title);
  font-size: 0.75rem;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
  color: white;
  background-color: rgb(255, 25, 100);
  border-radius: 0.25rem 0 0.25rem 0;
}

.snippet {
  position: relative;
  background: #fff;
  padding: 2rem 5%;
  margin: 1.5rem 0;
  box-shadow: 0 0.4rem 0.8rem -0.1rem rgba(0, 32, 128, 0.1), 0 0 0 1px #f0f2f7;
  border-radius: 0.25rem;
}

.examples .snippet:before {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 5px;
  content: attr(data-title);
  font-size: 0.75rem;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
  color: white;
  background-color: rgb(255, 25, 100);
  border-radius: 0.25rem 0 0.25rem 0;
}

.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 2rem 0;
  margin: 0 -5%;
  overflow: hidden;
}

.filter-contrast {
  filter: contrast(5);
  background-color: white;
}

.link.active,
span {
  font: normal normal 600 12px/18px Poppins;

  letter-spacing: 0px;
  color: #43425d;
}

.desc-semiBold {
  font: normal normal 400 16px/24px Poppins;
  letter-spacing: 0px;
  color: #4d4f5c;
}

.desc {
  font: normal normal 300 16px/24px Poppins;
  color: #4d4f5c;
}

.asc {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.order-image {
  width: 100%;
}

.css-14el2xx-placeholder {
  font: normal normal normal 12px/15px Poppins !important;
  color: #afafaf !important;
}

.css-6j8wv5-Input,
.css-319lph-ValueContainer {
  height: 45px;
  max-width: inherit !important;
}

.order-switch2 {
  background-image: url(../src/assets/img/check-circle.svg) !important;
  background-color: transparent !important;
  border-color: #707070 !important;
  box-shadow: none !important;
  width: 41px !important;
  height: 20px !important;
}

.text-title {
  text-align: left;
  font: normal normal 600 10px/16px Poppins !important;
  letter-spacing: 0px;
  color: #767676 !important;
  text-transform: uppercase;
}

.modal-img {
  width: 25rem;
}
.modalDressDetails  table {
  margin-left: 3.2rem;
}

input[type='date']:invalid::-webkit-datetime-edit {
  color: #afafaf !important;
}

.date-input:read-write {
  color: #000000 !important;
}

@media (min-width: 769px) and (max-width: 991px) {
  /* .login-bg {
    width: 49% !important;
  } */

  /* .style-screen {
    padding-right: 5rem !important;
    width: 50% !important;
  } */

  /* .bride-img {
    max-width: 90% !important;
    max-height: 100vh !important;
  } */

  .img-size {
    width: 105px !important;
    height: 46px !important;
    margin-top: -20px;
  }

  .desc-semiBold,
  .desc {
    font: normal normal 300 13px Poppins !important;
  }

  .link {
    font: normal normal 300 9px Poppins !important;
  }

  .margin-top-input {
    margin-top: -10px !important;
  }
}

@media (min-width: 992px) {
  .failure {
    color: red;
    text-align: center;
  }

  .img-size {
    margin-top: -20px;
  }
}

.date-input,
.date-input:focus {
  text-align: left;
  color: #afafaf !important;
  text-transform: uppercase;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
  font: normal normal normal 12px/15px Poppins !important;
  width: 9rem !important;
}

.style-screen-input {
  width: 120% !important;
}

.estimated_ship_date {
  width: 127px !important;
  color: #ffffff;
  background-color: #b8998d;
  border: none;
  font: normal normal normal 10px/15px Poppins;
  padding: 5px 8px;
}

.estimated_ship_date_container {
  font: normal normal normal 12px/21px Poppins;
  margin: 26px 5px 26px 26px;
  color: #ff0000 !important;
}

.ship_date_value {
  font: normal normal normal 10px/15px Poppins;
  padding: 5px 5px;
}

.background-hover:hover {
  color: #b8998d !important;
}

.brownText:hover {
  color: #b8998d !important;
}

.payment_input_box {
  border: 1px solid #dddddd;
  border-radius: 5px;
  width: 100%;
  height: 45px;
  padding: 12px 10px;
}

.logoFont-small {
  /* margin-top: -4rem !important; */
  background-color: #ffffff;
  max-height: fit-content !important;
  text-align: center !important;
}

.navbar-nav {
  display: block !important;
  padding-right: 0px !important;
}

.navbar {
  display: block !important;
  align-items: start !important;
}

.payment-button {
  border: 1px solid #b8998d !important;
  border-radius: 4px;
  width: inherit;
}

input[type='date']::-webkit-calendar-picker-indicator {
  background: url(./assets/img/calendar.svg) no-repeat;
  background-size: contain !important;
  color: #afafaf !important;
}

.error {
  color: red !important;
  font-size: 1rem !important;
  margin-bottom: 15px;
}



.flex-title {
  align-items: flex-end;
  justify-content: space-between;
}

.button-eye {
  background: white;
  border: none;
}

.round-gray-border-btn {
  background: inherit;
  border: 1px solid #c9c8ea;
  border-radius: 1.5rem;
  text-align: center;
  min-width: 106px;
  min-height: 47px;
  color: #c9c8ea;
}

.purple-round-btn {
  background: #c9c8ea;
  border: none;
  border-radius: 1.5rem;
  text-align: center;
  min-width: 106px;
  min-height: 47px;
  color: #fff;
}

.light-purple-round-btn {
  background: #b8998d 0 0 no-repeat padding-box;
  border: none;
  border-radius: 1.5rem;
  color: #fff;
  font-size: 10px;
  min-width: 79px;
  min-height: 26px;
}

@media (min-width: 1170px) {
  .bride-img {
    width: 85% !important;
    height: auto !important;
    margin: -60px 0px 0px 0px;
  }

  .style-screen {
    margin-left: -5rem !important;
    width: 45% !important;
  }
}

.border-raduis {
  width: 20rem !important;
  border: 1px solid #dddddd !important;
  border-radius: 5px;
}

.grayBorder {
  border: 1px solid #dddddd !important;
  border-radius: 5px !important;
  height: 45px;
  width: 8rem;
}

.width-section {
  width: 103% !important;
}

.width-header {
  width: 115% !important;
}

.font-label {
  font: normal normal normal 10px/15px Poppins;
}

.btn-width {
  width: 10rem !important;
  padding-inline: 5px !important;
  margin-top: -20px !important;
}

.width-row-store {
  width: 105% !important;
  border: 1px solid #dddddd !important;
  border-radius: 25px;
}

.width-btn-new {
  width: 15rem !important;
}

.width-payment {
  width: 15rem !important;
  border-radius: 25px;
  color: #b8998d !important;
  border: 1px solid #b8998d !important;
  background-color: transparent;
  height: 45px;
}

.light-gray10text {
  color: #afafaf;
}

.widthExpire {
  width: 8rem !important;
}

.widthHrControl {
  width: 104.5% !important;
}

@media only screen and (min-width: 787px) {
  /* .login-bg {
    width: 50% !important;
  } */

  /* .style-screen {
    width: 40% !important;
  } */

  .btn-main {
    width: fit-content !important;
  }
}

@media (min-width: 1200px) {
  .navbar-light {
    width: 18% !important;
  }
}

@media (max-width: 1199px) {
  .navbar-light {
    width: 20% !important;
  }
}

@media (max-width: 1099px) {
  .navbar-light {
    width: 25% !important;
  }
}

@media (max-width: 885px) {
  .navbar-light {
    width: 28% !important;
  }

  .navbar-toggler.d-flex.flex-column.justify-content-around.no-border.me-2.shadow-none {
    left: 100px;
    position: relative;
  }

  .navbar-toggler.d-flex.flex-column.justify-content-around.no-border.me-2.shadow-none.collapsed {
    left: 100px;
  }

  .row.grid-header.me-5.ps-3.fit-sales.small-width.width150 {
    width: 715px;
  }

  .grid-row-border.ps-3.py-3.me-5.fit-salesOrder {
    width: 700px;
  }

  .grid-row-border.ps-3.py-3.me-5.mt-4.ms-5.addresRow {
    width: 700px;
  }

  .col-lg-3.col-md-5.col-12.m-2.color-secondary.d-flex.align-items-center.address-container.ms-5.addresRow {
    width: fit-content;
  }

  .width-label {
    width: 13% !important;
  }

  .row.grid-header.mt-4.p-0.styleWidth {
    width: 648px;
  }
.width-subtitle-payment {
    width: 600px !important;
  }
}


@media (max-width: 885px) {
  .navbar-light {
    width: 25% !important;
  }
}

@media (min-width: 575px) and (max-width: 655px) {
  .navbar-light {
    width: 40% !important;
  }
}

@media (min-width: 653px) and (max-width: 740px) {
  .navbar-light {
    width: 35% !important;
  }
}

@media (min-width: 741px) and (max-width: 860px) {
  .navbar-light {
    width: 30% !important;
  }
}

.ctitle {
  text-decoration: underline;
}

.cflex {
  align-items: baseline;
}

.links.pt-5.nav-background {
  margin-bottom: 169px;
}


.date-payment {
  font-size: 0.6rem;
}

.snippet {
  display: none;
}

.navbar-toggler.d-flex.flex-column.justify-content-around.no-border.me-2.collapsed.shadow-none {
  top: -32px;
}

@media screen and (max-width: 995px) {
  .form-check-input2 {
    width: 25px !important;
  }
}

@media (min-width: 1160px) and(max-width:1240px) {
  .form-check-input2 {
    width: 25px !important;
  }
}

@media (min-width: 995px) and (max-width: 1132px) {
  .widthresp {
    width: 90% !important;
  }

  .form-check-input2 {
    width: 20px !important;
  }
}


@media (max-width: 435px) {
  .mrgnTpp{
    margin-top: -15px !important;
  }
  .waist{
    margin-left: -0.5rem !important;
  }
  .hips{
    margin-right: 1rem;
    margin-left: -2rem;
  }
  .hollow{
    max-width: 3rem !important;
    padding-top: 0.5rem !important;
  }
  .mobileMrgn{
    margin-left: -0.8rem;
  }
  .other{
    margin-left: -2.5rem;
  }
  .hllw{
        margin-left: -2rem;
        padding-top: 0.5rem !important;
  }
  .wst{
    margin-left: -1.5rem !important;
  }
  .fontCustom{
    font-size: 15px;
  }
  .remember-text {
    font-size: 13px !important;
  }
  .btn-add-address {
    margin-left: 2.3rem;
  }

  .sizeCheck {
    width: 45px !important;
  }
  .displayVisible {
    display: block !important;
  }
  .mrgnTopTrans{
    margin-top: -2rem !important;
  }
}
.navbar-toggler:not(.collapsed) {
  margin-left: 13rem !important;
}

@media only screen and (min-width: 1025px) {
  .login-bg {
    width: 50% !important;
  } 

   .style-screen {
    width: 40% !important;
  } 
}

 @media (min-width:700px) and (max-width: 1023px) {
   .navbar-toggler.d-flex.flex-column.justify-content-around.no-border.me-2.collapsed.shadow-none {
    top: -65px !important;
  }
}

@media (min-width:700px) and (max-width:777px) {
  .widthOnMiniTab{
    max-width: 768px !important;
    width: 94vw !important;
    }
  .head-container{
    max-width: 583px !IMPORTANT;
  }
  .width-Inv{
    width: 12% !important;
  }
  .statusWidthMobileM{
    width: 12% !important;
  }
  .width-Invdate {
    width: 12% !important;
}
.invoice .width-Inv {
  width: 12% !important;
}
.invoice .width-amount {
  
  margin-right: 0.8rem;
}
.width-InvStatus {
  width: 10% !important;
}
.width-InvShip {
  width: 12% !important;
}
.widthOnMiniTab{
  /*width: 12% !important;*/
}
.mrgnLftSta {
  margin-left: 0 !important;
}
.shippingCostInMini{
  width: 10% !important;
}
.widthInv {
  width: 152% !important;
}
.width-200 {
  width: 142% !important;
}
.mrgnRght {
  margin-left: 0 !important;
}
.widthPaymentOnTablet {
  width: 91vw !important;
}
.payment-button {
  width: 91% !important;
}
.payment-check{
  width: 12% !important;
}
.width-transaction {
  width: 5rem !important;
}
.row-transaction {
  width: 60vw !important;
}

.width-itemSales {
  width: 15% !important;
}
.width-check {
  width: 2rem !important;
}
.width-sales {
  width: 95px !important;
}
.width-salesStat {
  width: 1rem !important;
}
.width-amountCha {
  width: 1rem !important;
  margin-left: -2rem;
  margin-right: 6rem;
}
.statusMrgn {
  margin-left: 0 !important;
}
.marginInMiniIpadToLeft{
  margin-left: 2rem !important;
}

}
@media screen and (width:810px) {
  .widthTitlesSummaryTab {
    width: 100vw !important;
}
  .widthSummary {
    width: 85vw !important;
}
.width-subtitle-payment {
  width: 100% !important;
}
.paddingToLeftTab{
  padding-left: 3rem !important;
}
.paddingToLeft{
  padding-left: 4rem !important;
}
.widthPaymentOnTablet {
  width: 96vw !important;
}
.payment-button {
  width: 96% !important;
}
.row-transaction {
  width: 83vw !important;
}
}
@media only screen and (width: 1024px) {
 
  .topMargin{
    display: flex !important;
    top: 5rem !important;
  }
}
@media only screen and (width: 744px) {

  .widthLeft {
      margin-left: 40%  !important;
    }
}

@media only screen and (width: 1024px) {
.navbar-toggler.collapsed .top-bar {
  margin-top: -12px !important;
}
.navbar-toggler.collapsed .bottom-bar {
  margin-top: 12px !important;
}
.button-smaller{
  width: 58rem !important;
}

}

@media only screen and (width: 810px) {
.btnEx {
  padding-left: 0rem !important;
}
}
@media screen and (min-width: 436px) {
  .startMrgn{
margin-left: 2rem !important;
  }
}
@media screen and (min-width: 436px) {
  .startMrg {
    margin-left: 0.5rem !important;
  }
}

.CODText{
  font-size: 15px;
  color:#b8998d;
}

.text-area-style{
  width: 100%;
}
.widthTitle{
  width: 3rem;
}

